<template>
	<div class="programm">
        <div v-if="loading">
            <svg width="1em" height="1em" viewBox="0 0 20 20" focusable="false" role="img" alt="icon" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi-spin ml-1 bi-arrow-clockwise bi-spin ml-1 b-icon bi" style="font-size: 300%;"><g><path fill-rule="evenodd" d="M10 4.5a5.5 5.5 0 105.5 5.5.5.5 0 011 0 6.5 6.5 0 11-3.25-5.63l-.5.865A5.472 5.472 0 0010 4.5z" clip-rule="evenodd"></path><path fill-rule="evenodd" d="M10.646 1.646a.5.5 0 01.708 0l2.5 2.5a.5.5 0 010 .708l-2.5 2.5a.5.5 0 01-.708-.708L12.793 4.5l-2.147-2.146a.5.5 0 010-.708z" clip-rule="evenodd"></path></g></svg>
        </div>
        <div v-else-if="error" style="color:red; padding-bottom:30px;">{{ error }}<br><br>Bitte laden Sie diese Seite zu einem späteren Zeitpunkt noch einmal.</div>
        <div v-else-if="programm && programm.published">
            <nav class="room-chooser">
                <ul>
                    <li v-for="(room, rIndex) in programm.rooms" :key="'room-'+rIndex">
                        <a 
                            href="#" 
                            @click.prevent="roomIndex=rIndex" 
                            :class="roomIndex===rIndex?'active':''"
                            :title="room.name+' wählen für Programmansicht'"
                        >{{ room.name }}</a>
                    </li>
                </ul>
                
            </nav>

            <table v-for="(room, rIndex) in programm.rooms" :key="'room-'+rIndex" :title="room.name" class="room table table-bordered" v-show="roomIndex===rIndex">
                <thead v-if="0">
                    <tr>
                        <th>Zeit</th>
                        <th>Veranstaltung</th>
                        <th>Referierende</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(event, eventIndex) in trs" :key="'event-'+eventIndex" :class="'event'+(' type-'+event.class)" :title="event.title">
                        <td class="time">
                            <span v-if="event.showTime">{{ event.timeFrom }}-{{ event.timeTo }} Uhr</span>
                        </td>
                        <td class="content">
                            <div class="title">{{ event.title }}</div>
                            <div class="description" v-html="event.description"></div>
                            <pre v-if="0">{{ event.subEvents }}</pre>

                            <div v-show="event.persons && event.type == 'subEvent'" class="persons">
                                <div v-for="(person, personIndex) in event.persons" :key="'person-'+personIndex" class="person">
                                    <div class="image" :style="'background-image: url(\''+(person.portrait?person.portrait.src:'https://content.ddz.de/corporate/images/person-dummy.png')+'\')'" :title="'Foto von '+person.name"></div>
                                    <div class="name">{{person.name}}</div>
                                    <div class="description" v-html="person.description"></div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div v-else class="alert alert-sm alert-primary">Das Programm ist derzeit in Planung. Es wird zu einem späteren Zeitpunkt an dieser Stelle veröffentlicht.</div>
	</div>
</template>

<script>


import assetsProgramm from '@/assets/programm.json'
import axios from 'axios';

export default {
	name: 'webComponent',
	components:{},
    props:{
        file: {
            type: String,
            default: "https://neu.duesseldorfer-diabetestag.de/wp-content/plugins/ddt-admin/api/getTimetable"
        }
    },
	data: function () {
        
		return {
            axios,
            url:this.file,
            assetsProgramm,
            loading:true,
            testProgressAnimation:0, // ms | false | 0
            error:false,
			programm:false,
			roomIndex:0
		}
	},
    async created(){
        this.programm = await this.load();
        this.loading = false;
    },
    methods:{
        async load(){

            // if( this.url.indexOf('localhost.') > -1){

            //     (async () => {
            //         if( this.testProgressAnimation )
            //             await new Promise((res) => setTimeout(res, this.testProgressAnimation));
            //         resolve(this.assetsProgramm);
            //     })();
            //     return true;
            // }
            
            var response;
            try {
                response = await axios.post(this.url);
                
            } catch (error) {
                response = false;
                console.log(error);
            }

            console.log("response", response);
            return new Promise((resolve, reject)=>{
                if( ! response || typeof response.data == 'undefined' )
                {
                    this.error = "Die Datendatei enthält kein gültiges JSON-Format.";
                    reject();
                }

                (async () => {
                    if( this.testProgressAnimation )
                        await new Promise((res) => setTimeout(res, this.testProgressAnimation));
                    resolve(response.data);
                })();
            
                return true;
            });
        },
    },
    computed:{
        trs(){
            var trs = [];
            var events = this.programm.rooms[this.roomIndex].events;

            for (let index = 0; index < events.length; index++) {
                const event = events[index];
                event.type = "event";
                event.class = "event";

                if( event.title.toLowerCase().trim() == "pause" )
                    event.class += ' pause';

                trs.push(event);

                if( event.subEvents )
                {
                    for (let index = 0; index < event.subEvents.length; index++) {
                        const subEvent = event.subEvents[index];
                        subEvent.type = "subEvent";
                        subEvent.class = "subevent";
                        if( subEvent.title.toLowerCase().trim() == "pause" )
                            subEvent.class += ' pause';
                        trs.push(subEvent);
                    }
                }
            }

            return trs;
        }
    }
}
</script>

<style type="text/scss">
.bi-spin {
  -webkit-animation: bi-spin 2s infinite linear;
  animation: bi-spin 2s infinite linear;
}
.bi-pulse {
  -webkit-animation: bi-spin 1s infinite steps(8);
  animation: bi-spin 1s infinite steps(8);
}
@-webkit-keyframes bi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes bi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
</style>

<style>

.programm .room-chooser{
	margin-bottom:25px;
}

.programm .room-chooser ul{
    list-style-type: none;
    margin:0px;
    padding:0px;
}
.programm .room-chooser ul li{
    display:inline-block;
}

.programm .room-chooser a{
	display:inline-block;
	color:black;
	background-color: #eaf4f4;
	padding:15px 25px;
	text-decoration: none;
	font-weight:bold;
}
.programm .room-chooser a.active{
	font-weight:bold;
	color:black;
	background-color: #bddadf;
}
.programm .room-chooser li:not(:first-child)
{
    margin-left:20px;
}

.programm .room-chooser :focus-visible{
    outline: 2px solid black;
}

.programm{
	font-family: Arial, Helvetica, sans-serif;

    text-align:center;
}

.programm table 
{
	width:100%;
	max-width:1000px;
	border-collapse: collapse;
    margin-left:auto;
    margin-right:auto;
}

.programm table th{
	text-align: left;
}

.programm table th,
.programm table td{
	padding:25px;
}



.programm table td{
	text-align: left;
	vertical-align: top;
	width:auto;
}

.programm table td:first-child{
	white-space: nowrap;
	width:1px;
}
/* .programm table td:last-child{
	width:260px;
} */

.programm .event > td{
	border-top:1px solid #ddd;
    min-width:210px;
}
.programm .event:last-child > td{
	border-bottom:1px solid #ddd;
}

.programm .event td{
	background-color: #eaf4f4;
}

.programm .event.type-event td
{
	background-color: #1d7382;
    color:white;
}

.programm .event.pause td
{
	background-color: #FFFFFF;
    color:black;
}

.programm .event.type-event.pause td
{
	background-color: white;
    color:black;
}


.programm .event.type-event .time{
	font-weight: bold;
	/* color:#0096a5; */
	color:#FFF;
    font-size:1.3em;
}

.programm .event.type-subevent .time{
	
    font-weight: bold;
	color:#000000;
    font-size:1.15em;
}

.programm .event.type-event .title{
	font-weight: 700;
    font-size:1.3em;
}

.programm .event.type-subevent .title{
	
	font-weight: bold;
    font-size:1.15em;
}

.programm .event .persons{
    margin-top:1em;
    display:flex;
    flex-wrap: wrap;
    gap:40px;
}

.programm .event .person{
    /* max-width:200px; */
    font-size:0.85em;
    line-height: 1.3em;
}

.programm .event .person:not(:last-child){
	/* margin-right:25px; */
}

.programm .event .person .name{
	font-weight: bold;
}
.programm .event .person .description{
	/* font-size:0.85em; */
}

.programm .alert{
    display:inline-block;
    padding:30px;
}

.programm .alert-primary{
    background-color: #eaf4f4;
    border-color: #cddddd;
    color:black;
}

.programm .event .person .image{
	margin-bottom:10px;
	width:80px;
	height:80px;
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	border-radius:50%;
}


@media (width <= 1000px) {

    .programm .room-chooser li:not(:first-child)
    {
        margin-left:3px;
    }

    .programm .room-chooser li
    {
        margin-bottom:3px;
    }

    table{
        display:block;
    }

    th{
        display:none;
    }
    td{
        display:block;
        width:unset !important;
        border:none !important;
        padding:25px !important;
    }
    td.time{
        padding-bottom:0px !important;
    }

    td.content{
        padding-top:0px !important;
    }

}



</style>